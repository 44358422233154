import React from 'react';

import { useSelector } from 'react-redux';

import CheckMobile from '../../Util/MobileCheck';

import { StyledMainBanner } from './style';

const BannerSection = () => {
    const { lang } = useSelector((state) => state.i18nReducer);
    const { r } = useSelector((state) => state.languageReducer);

    const t = r[lang];

    const isMobile = CheckMobile();

    return (
        <StyledMainBanner>
            <p
                className={`headTitle ${lang}`}
                dangerouslySetInnerHTML={{
                    __html: isMobile
                        ? t['newMainPage_mb_headTitle_1']
                        : t['newMainPage_pc_headTitle_1'],
                }}
            />
            <p
                className={`bodyText ${lang}`}
                dangerouslySetInnerHTML={{
                    __html: isMobile ? t['newMainPage_mb_bodyText'] : t['newMainPage_pc_bodyText'],
                }}
            />
        </StyledMainBanner>
    );
};

export default BannerSection;
